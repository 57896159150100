import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import { includes, keys } from "lodash";

import TeacherLogin from "./components/TeacherLogin";
import WebCamCapture from "./components/WebCamCapture";
import StudentList from "./components/StudentList";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHomeButton: false
    };
  }

  componentDidMount() {
    const { token, classroom_id } = this.getCurrentUser() || {};

    let requestOptions = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };

    const apiBase = process.env.REACT_APP_API_URL || "https://app.chapterone.org/api/v2";

    if (token) {
      requestOptions.headers["Authorization"] = `Bearer ${token}`;

      if (classroom_id) {
        fetch(`${apiBase}/deployment_flags/${classroom_id}`, requestOptions)
          .then((response) => response.json())
          .then((json) => {
            if (includes(keys(json), "WORD_WHIRLED")) {
              this.setState({ showHomeButton: true });
            }
          });
      }
    }
  }

  getCurrentUser = () => {
    const { cookies } = this.props;

    const currentUser = cookies.get("userRoles");
    const token = cookies.get("authToken");

    return currentUser && token ? { ...currentUser, token } : null;
  };

  loggedIn = () => {
    const user = this.getCurrentUser();
    if (user === null || user.token === "") {
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <HttpsRedirect>
        <BrowserRouter>
          <div>
            <Route
              exact
              path="/"
              render={(props) =>
                this.loggedIn() ? (
                  <Redirect
                    to={
                      this.getCurrentUser().roles.find((role) => role === "tutor") ? "/webcamcapture" : "/studentlist"
                    }
                  />
                ) : (
                  <TeacherLogin {...props} />
                )
              }
            />
            <Route
              path="/webcamcapture"
              render={(props) => (this.loggedIn() ? <WebCamCapture props={props} /> : <Redirect to="/" />)}
            />
            <Route
              path="/studentlist"
              render={() =>
                this.loggedIn() ? <StudentList showHomeButton={this.state.showHomeButton} /> : <Redirect to="/" />
              }
            />
          </div>
        </BrowserRouter>
      </HttpsRedirect>
    );
  }
}

export default withCookies(App);
